import { render, staticRenderFns } from "./SummaryDepositOutput.vue?vue&type=template&id=45bb2f2e&scoped=true"
import script from "./SummaryDepositOutput.vue?vue&type=script&lang=js"
export * from "./SummaryDepositOutput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45bb2f2e",
  null
  
)

export default component.exports